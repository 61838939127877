








import PersonalCard from '@/components/Settings/Cards/Personal.vue';
import ContactCard from '@/components/Settings/Cards/Contact.vue';

export default {
  name: 'Settings',
  components: {
    PersonalCard,
    ContactCard,
  },
};
